@import "../styles/variables";

.popupWrapper {
  position: absolute;
  top: 100%;
  width: 90vw;
  max-width: 350px;
  border-radius: 10px;
  margin-top: 1rem;
  z-index: 1001;
  box-shadow: $shadow;
  background: $white;
  overflow: hidden;
  left: 0;
  cursor: default;
  &:before {
    content: "";
    position: absolute;
    display: block;
    top:-8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid $white;
  }
  &.popupright {
    left: auto;
    right: 0;
    transform: scale(0);
    opacity: 0;
    animation: popin .3s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.71,.55,.62,1.57);
    &:before {
      right: 2rem;
    }
    &.closing {
      animation: popoutRight .3s;
    }
  }
  &.popupcenter {
    transform: translateX(-50%) scale(0);
    opacity: 0;
    animation: popinCenter .3s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.71,.55,.62,1.57);
    &:before {
      left: 50%;
      transform: translateX(-50%);
    }
    &.closing {
      animation: popoutCenter .3s;
      @media (max-width: $phone) {
        animation: popoutRight .3s;
      }
    }
  }
  &.popupleft {
    &:before {
      left: 2rem;
    }
  }
  &.popupnone {
    &:before {
      display: none;
    }
  }
  .popupInner {
    .popupHeader {
      padding: .5rem;
      min-height: 32px;
      position: relative;
      .popupClose {
        position: absolute;
        right: 1rem;
        top: 50%;
        margin-top: -8px;
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
        &:before, &:after {
          position: absolute;
          left: 7px;
          content: ' ';
          height: 17px;
          width: 2px;
          background-color: $blue;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
    .popupHeaderText {
      font-size: 22px;
      padding: 4px 0 4px 22px;
    }
    .popupContent {
      width: 100%;
      padding: 0;
      max-height: 560px;
      overflow-y: auto;
    }
  }
  .popupWrapper {
    @media (max-width: $phone) {
      position: static;
      border-radius: 0;
      margin: 0;
      &:before {
        content: none;
      }
    }
    .popupHeader {
      display: none;
    }
  }
}
@keyframes popin {
  0% {
    transform: scale(0.6);
    opacity: .5;
  }
  95% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes popoutRight {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  5% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: .5;
  }
}
@keyframes popinCenter {
  0% {
    transform: translateX(-50%) scale(0.6);
    opacity: .5;
  }
  95% {
    transform: translateX(-50%) scale(1.05);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }
}
@keyframes popoutCenter {
  0% {
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }
  5% {
    transform: translateX(-50%) scale(1.05);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) scale(0);
    opacity: .5;
  }
}
