@import "../styles/variables";

.wrapper {
  display: flex;
  flex-direction: row;
  border: 1px solid $blue;
  padding: 25px 60px 25px 38px;
  align-items: center;
  @media (max-width: $phone) {
    flex-direction: column;
    padding: 25px 40px;
  }
  &.noBorder {
    border: none;
  }
}
.logo {
  color: $blue;
  padding: 0 26px 0 0;
  border-Right: 1px solid $blue;
  @media (max-width: $phone) {
    padding: 0 0 26px 0;
    border: none;
  }
  & > svg {
    width: 145px;
    max-height: 100%;
  }
}
.newsletter {
  padding: 0 0 0 24px;
  @media (max-width: $phone) {
    padding: 0;
  }
  & > h3 {
    font-Size: 1.5rem;
    color: $blue;
    margin-Bottom: 16px;
  }
  &:only-child {
    padding-left: 0;
  }
}
.input {
  border-Bottom: 1px solid #979797;
  margin-Bottom: 16px;
  display: flex;
  justify-Content: space-between;
  padding: 0 0 8px 0;
  & > input {
    outline: none;
    border: none;
    background-Color: transparent;
    color: $grey2;
    font-Family: $bodyText;
    font-Size: 15px;
    padding: 0 32px 0 0;
    flex-Grow: 1;
  }
  & > button {
    outline: none;
    border: none;
    background-Color: transparent;
    color: $red;
    font-Family: $bodyText;
    font-Size: 15px;
    font-Weight: 700;
    padding: 0;
  }
}
.acceptance {
  font-Size: 12px;
  color: $grey2;
  display: flex;
  flex-Direction: row;
  align-Items: center;
  & a {
    color: $grey2;
  }
}
.subscribed {
  text-align: center;
}
