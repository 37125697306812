@import "../styles/variables";

.languageSelection {
  flex-grow: 1;
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  border-left: none;
  padding-left: 0;
  @media (max-width: $tabletLand) {
    padding-left: 28px;
    border-left: 1px solid $grey1;
  }
  .languageSelectionInner {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  svg {
    width: 20px !important;
    height: 20px;
    color: white;
    @media (max-width: $tabletLand) {
      color: $blue;
      width: 15px !important;
      height: 25px;
    }
  }
  .currency {
    margin: 0 .5rem;
    color: white;
    font-size: .8rem;
    @media (max-width: $tabletLand) {
      color: $blue;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .chevron {
    width: 13px;
    height: auto;
    cursor: pointer;
  }
}
.languageSelect {
  padding: 1rem;
  h4 {
    color: $grey2;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    font-weight: 500;
  }
  select {
    width: 100%;
    padding: 1rem;
    color: $grey3;
    border: 1px solid $grey1;
    font-family: Montserrat, sans-serif;
  }
  hr {
    width: 100%;
    height: 5px;
    background-color: $blue;
    margin: 0.16rem 0 0 0;
    border: none;
  }
}
.buttonWrapper {
  padding: 1rem;
}
.headerAlt {
  padding: 1rem;
  align-items: center;
  //width: 200px;
  flex-grow: 0;
  margin-left: auto;
  @media (max-width: $tabletLand) {
    align-items: flex-start;
    flex: auto;
  }

  .currency,
  svg {
    color: $blue;
  }
}
