@import "../../styles/variables";
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.4);
  z-index:1001;
}
.popupWrapper {
  position: fixed;
  top: 50%;
  width: 90vw;
  max-width: 510px;
  border-radius: 10px;
  box-shadow: $shadow;
  background: $white;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0);
  opacity: 0;
  animation: popinCenter .3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.71,.55,.62,1.57);
  height: 90vh;
  overflow: hidden;
  cursor: default;
  &:before {
    left: 50%;
    transform: translateX(-50%);
  }
  &.closing {
    animation: popoutCenter .3s;
  }
  .popupInner {
    height: 100%;
    .popupHeader {
      padding: .5rem;
      min-height: 32px;
      position: relative;
      .popupClose {
        position: absolute;
        right: 1rem;
        top: 50%;
        margin-top: -8px;
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
        &:before, &:after {
          position: absolute;
          left: 7px;
          content: ' ';
          height: 17px;
          width: 2px;
          background-color: $blue;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
    .popupHeaderText {
      padding: 4px 0 4px 22px;
      color: $blue;
    }
    .popupContent {
      width: 100%;
      padding: 0;
      height: calc(100% - 48px);
    }
  }
  .popupWrapper {
    @media (max-width: $phone) {
      position: static;
      border-radius: 0;
      margin: 0;
      &:before {
        content: none;
      }
    }
    .popupHeader {
      display: none;
    }
  }
}
.popupWrapperSmall {
  max-width: 382px;
}
.popupWrapperLarge {
  max-width: 700px;
}
.login {
  border-top: 1px solid $grey1;
  padding: 5px 14px 30px 30px;
  height: calc(100% - 78px);
  overflow-y: auto;
  p {
    color: $blue;
    font-weight: 500;
  }
  .registerError {
    margin: 1rem 0;
    color: $red;
    font-size: 1.25rem;
  }
  .form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .field {
    display: block;
    width: 50%;
    margin-bottom: 12px;
    @media (max-width: $phone) {
      width: 100%;
    }
    label {
      text-transform: uppercase;
      display: block;
      font-size: 12px;
    }
    input,
    .select {
      border: 1px solid $grey3;
      padding: 11px 20px;
      width: calc(100% - 16px);
      margin-right: 16px;
      font-weight: 500;
      font-family: $bodyText;
      cursor: pointer;
      &[type=checkbox] {
        width: auto;
        opacity: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        position: relative;
        z-index: 1;
        &:checked ~ div {
          & svg {
            display: block;
          }
        }
      }
    }
    .select {
      padding: 0;
      height: 40px;
      select {
        border: none;
        outline: none;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        font-family: $bodyText;
      }
    }
    .checkbox {
      width: 24px;
      height: 24px;
      border: 1px solid $grey3;
      border-radius: 3px;
      display: inline-block;
      vertical-align: middle;
      margin: 18px 10px 0 0;
      position: relative;
      ~ label {
        display: inline-block;
        vertical-align: middle;
        text-transform: initial;
        margin-top: 18px;
        font-size: 14px;
      }
    }
    .icon {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      & svg {
        display: none;
      }
    }
    .strengthIndicator {
      margin-right: 16px;
    }
    .passwordConfirm {
      margin-top: 12px;
      margin-right: 16px;
      font-size: 10px;
      line-height: 1;
      text-align: right;
      font-weight: 500;
      &.noMatch {
        color: $red
      }
      &.match {
        color: $green;
      }
    }
  }
  .fieldWide {
    width: 100%;
  }
}
.loginNoReg {
  height: 100%;
}
.loginFooter {
  border-top: 1px solid $grey1;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  @media (max-width: $phone) {
    display: block;
  }
  span {
    color: $green;
    text-decoration: underline;
    font-weight: 600;
    margin-left: 12px;
    cursor: pointer;
  }
  button {
    margin-left: auto;
    margin-right: 0;
    @media (max-width: $phone) {
      margin-top: 10px;
      display: block;
    }
  }
}
.datePicker, .timePicker{
  border-top: 1px solid $grey1;
  padding: 5px 30px 30px;
  h3 {
    font-family: $bodyText;
    margin: 20px 0;
    color: $grey2;
    font-weight: 600;
  }
}
.timePicker {
  border-top: 1px solid $grey1;
  padding: 5px 20px 30px 30px;
}
.dates,
.times {
  li {
    display: inline-block;
    padding: 2px 8px;
    font-weight: 600;
    width: 56px;
    text-align: center;
    border: 1px solid $grey1;
    border-radius: 2px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    &.unavailable {
      border-color: rgba($grey1, 0.3);
      background: rgba($grey1, 0.3);
      color: $grey1;
      opacity: 0.4;
      cursor: default;
    }
    &.selected {
      background: $green;
      border-color: $green;
      color: $white;
      cursor: default;
    }
  }
}
.dates li{
  width: auto;
}
.overview {
  border-top: 1px solid $grey1;
  padding: 30px;
  color: $black;
  height: 100%;
  overflow-y: scroll;
  .gallery {
    margin-bottom: 30px;
    .chevronRight,
    .chevronLeft {
      width: 50px;
      background: none;
      border: 0;
      position: relative;
      padding:0 15px;
      outline: none;
      transition: transform 0.2s ease-out;
      cursor: pointer;
      svg path {
        fill: $white;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
    .chevronLeft {
      transform: rotateZ(180deg);
      &:hover {
        transform: rotateZ(180deg) scale(1.1);
      }
    }
    //.slider-control-bottomcenter {
    //  display: none !important;
    //}
    .image {
      width: 100%;
      max-height: 350px;
      object-fit: cover;
      img { 
        width: 100%;
      }
    }
    li button {
      svg {
        fill: $white;
      }
    }
  }
  .map {
    height: calc(100% - 30px);
  }
  h2 {
    font-size: 28px;
    color: $blue;
  }
  h3 {
    font-size: 20px;
    color: $blue;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    line-height: 22px;
  }
  ul {
    list-style: disc;
    margin: 0 0 0 40px;
    li {
      margin: 5px 0 2px;
      //display: flex;
      align-items: center;
      //font-weight: 600;
      //font-size: 14px;
      color: $blue;
      ul {
        list-style: circle;
        margin: 0 0 0 40px;
      }
    }
  }
  svg {
    font-size: 18px;
    margin-right: 8px;
    path {
      fill: $red;
    }
  }
}
.overviewContent {
  padding: 0 20px;
}
.overviewFooter,
.datePickerFooter {
  border-top: 1px solid $grey1;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  button {
    margin: 0;
  }
  span {
    margin-right: auto;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    color: $grey3;
    font-weight: 600;
  }
}
.overviewFooter {
  padding: 20px 50px;
}
.footerDetails {
  display: flex;
  align-items: center;
  width: 100%;
}
.footerElement {
  flex: 1;
  .label {
    font-family: $bodyText;
    color: $grey2;
    font-weight: 600;
    font-size: 14px;
  }
  .value {
    font-weight: 600;
    font-size: 18px;
  }
  .small {
    font-weight: 400;
    font-size: 12px;
    display: inline;
  }
  .total {
    font-weight: 400;
    font-size: 12px;
  }
}
.checkoutBody {
  border-top: 1px solid $grey1;
  padding: 24px 24px 0;
}
.checkoutFooter {
  border-top: 1px solid $grey1;
  padding: 18px 24px;
  color: $blue;
  span {
    display: block;
    text-align: right;
    font-size: 14px;
    margin-bottom: 10px;
    span {
      display: inline-block;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.checkoutItem {
  font-size: 14px;
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  img {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    object-fit: cover;
  }
  div {
    flex: 1;
  }
  span {
    color: $blue;
    display: block;
    font-size: 13px;
    line-height: 16px;
  }
  .checkoutPrice {
    float: right;
    font-weight: 600;
    margin-left: 20px;
    font-size: 14px;
  }
  h3 {
    font-family: $bodyText;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .checkoutRemove {
    color: $black !important;
    margin-top: 8px;
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
  }
}
.checkoutPending {
  color: $red;
  font-size: 12px;
  margin: -8px 0 18px;
  font-weight: 500;
  text-decoration: underline;
  &:before {
    content: '!';
    margin-right: 6px;
    background: $red;
    color: $white;
    width: 16px;
    height: 16px;
    display: inline-block;
    text-align: center;
    line-height: 16px;
    border-radius: 50%;
  }
}

.invalid {
  border-color: $red;
  background: rgba($red, 0.2);
}
@keyframes popinCenter {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(0.6);
    opacity: .5;
  }
  95% {
    transform: translateX(-50%) translateY(-50%) scale(1.05);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) scale(1);
    opacity: 1;
  }
}
@keyframes popoutCenter {
  0% {
    transform: translateX(-50%) translateY(-50%) scale(1);
    opacity: 1;
  }
  5% {
    transform: translateX(-50%) translateY(-50%) scale(1.05);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) scale(0);
    opacity: .5;
  }
}
