@import "../../styles/variables";
.login {
  color: white;
  display: flex;
  align-items: center;
  font-weight: 300;
  position: relative;
}
.accountNavigation {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-top: 1px solid $grey1;
  .menu {
    ul {
      display: block !important;
      li {
        width: 100%;
        margin-right: 0 !important;
        display: block !important;
        border-bottom: 1px solid $grey1;
        a {
          text-align: center !important;
          font-size: 1.25rem !important;
          padding: 1rem !important;
          display: block;
          color: $blue !important;
          font-family: $titleText;
          font-weight: 500;
          text-transform: none;
        }
      }
    }
  }
  .secondary {
    padding: 1rem 0;
    ul {
      flex-wrap: wrap;
      li {
        width: 50%;
        margin-right: 0 !important;
        margin-bottom: 1rem;
        padding: 0 0 0 1.4rem;
        a, span {
          font-size: 0.9rem;
          color: $grey2 !important;
          font-weight: 500;
          text-transform: none;
        }
      }
    }
  }
}
