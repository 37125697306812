@import "../../styles/variables";
.header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $grey1;
  position: fixed;
  width: 100%;
  background-color: $white;
  z-index: 10;
  @media (max-width: $phone) {
    height: $headerHeightSml;
  }
  .logoWrapper {
    padding: 1.394rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 20%;
    background: $white;
    border-right: 1px solid $grey1;
    cursor: pointer;
    @media (max-width: $phone) {
      width: 143px;
      background: $blue;
      padding: 1rem;
    }
    svg {
      height: 53px;
      max-height: 53px;
      @media (max-width: $phone) {
        width: 100%;
        height: auto;
      }
      path {
        @media (max-width: $phone) {
          fill: $white;
        }
      }
    }
  }
  .toggleNav {
    border-left: 1px solid $grey1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    padding: 0 42px;
    align-items: center;
    @media (max-width: $tablet) {
      width: auto;
      padding: 0 22px;
    }
    .hamburger {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 2rem;
      height: 2rem;
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
      z-index: 10;

      &:focus {
        outline: none;
      }
      span {
        width: 2rem;
        height: 0.25rem;
        background: $blue;
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
        &:first-child {
          transform: rotate(0);
        }
        &:nth-child(2) {
          opacity: 1;
          transform: translateX(0);
        }
        &:nth-child(3) {
          transform: rotate(0);
        }
      }
      &.hamburgerOpen {
        span {
          &:first-child {
            transform: rotate(45deg) translateX(4px) translateY(-4px);
          }

          &:nth-child(2) {
            opacity: 0;
            transform: translateX(20px);
          }

          &:nth-child(3) {
            transform: rotate(-45deg) translateX(4px) translateY(4px);
          }
        }
      }
    }
  }
  .mainNavigation {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .topNav {
      display: flex;
      flex-direction: row;
      background-color: $blue;
      color: $white;
      padding: 0.55rem 1.3rem;
      font-size: 0.8rem;
      line-height: 1rem;
      .languageSelection {
        flex-grow: revert;
        padding: 0;
        color: $white;
      }
      .secondaryNav {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        & ul {
          display: flex;
          flex-direction: row;
          & li {
            margin-right: 2rem;
            display: flex;
            align-items: center;
            font-weight: 300;
            @media (max-width: $phone) {
              margin-right: 1rem;
            }
            a {
              color: white;
            }
            svg {
              height: 20px;
              width: 20px;
              margin-right: 10px;
            }
            &:nth-child(1), &:nth-child(2) {
              display: flex;
              text-transform: uppercase;
              @media (max-width: $tablet) {
                display: none;
              }
            }
            &:last-child {
              margin-right: 0;
            }
            & > span {
              display: inline;
              @media (max-width: $phone) {
                display: none;
                margin-left: .5rem;
              }
            }
          }
          .basket {
            > span {
              display: none;
            }
          }
          .search {
            span {
              display: none;
            }
          }
        }
      }
    }
    .mainMenu {
      .menu {
        & > ul {
          display: flex;
          flex-direction: row;
          & > li {
            width: 20%;
            border-left: 1px solid $grey1;
            font-size: 1.125rem;
            line-height: 1.375rem;
            text-align: center;
            position: relative;
            padding: 3px;
            @media (max-width: $tablet) {
              font-size: 1rem;
            }
            > a, > span {
              padding: 14.5px 0;
              display: block;
              color: $blue;
              font-family: Domaine,serif;
              font-weight: 600;
              cursor: pointer;
              border-bottom: 3px solid transparent;
              position: relative;
              svg {
                height: 1rem;
                margin-left: .5rem;
              }
              &:after {
                display: block;
                content: "";
                height: 3px;
                width: 0;
                background-color: $red;
                top: 100%;
                left: 50%;
                transform: translateX(-50%) scaleX(0);
                transition: all .3s;
                position: absolute;
              }
              &:hover {
                &:after {
                  width: 100%;
                  left: 50%;
                  transform: translateX(-50%) scaleX(1);
                }
              }
            }
            &.active {
              > a, > span {
                border-color: $red;
                &:after {
                  display: none;
                }
              }
            }
            & .subMenu {
              & li {
                border-top: 1px solid $grey1;
                & > a {
                  padding: 1rem 0;
                  display: block;
                  color: $blue;
                  font-family: Domaine,serif;
                  cursor: pointer;
                  font-weight: 400;
                }
                &:first-child {
                  & > a {
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
      }

    }
  }
  .mainMobileNavigation {
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
    border-top: 1px solid $grey1;
    .topNav {
      .secondaryNav {
        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 1rem 0;
          li {
            width: 48%;
            padding: 0 1.4rem;
            color: $grey2;
            font-weight: 500;
            font-size: 0.9rem;
            line-height: 1.2rem;
            margin-bottom: 1rem;
            svg {
              display: none;
            }
          }
          .search {
            width: 100%;
            flex-grow: 1;
            border-top: 1px solid $grey1;
            margin-bottom: 0;
            padding-top: 1rem;
            color: $grey3;
            font-family: Domaine, serif;
            font-size: 1.1rem;
            display: flex;
            flex-direction: row;
            svg {
              display: initial;
              color: $blue;
              height: 19px;
            }
            span {
              margin-left: .5rem;
            }
          }
        }
      }
    }
    .mainMenu {
      ul {
        li {
          border-bottom: 1px solid $grey1;
          > a, > span {
            text-align: center;
            font-size: 1.25rem;
            padding: 1rem;
            display: block;
            color: $blue;
            font-family: Domaine,serif;
            font-weight: 500;

            svg {
              margin-left: 5px;
            }
          }
          &:last-child {
            @media (max-width: $phone) {
              ul li:last-child {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
.languageSelect {
  padding: 1rem;
  h4 {
    color: $grey2;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    font-weight: 500;
  }
  select {
    width: 100%;
    padding: 1rem;
    color: $grey3;
    border: 1px solid $grey1;
    font-family: Montserrat, sans-serif;
  }
  hr {
    width: 100%;
    height: 5px;
    background-color: $blue;
    margin: 0.16rem 0 0 0;
    border: none;
  }
}
.buttonWrapper {
  padding: 1rem;
}
