@import "../styles/variables";
.button {
  display: inline-block;
  padding: 0.75rem;
  color: $white;
  font-weight: 500;
  font-size: 0.8rem;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 3px;
  //box-shadow: 2px 2px 8px 2px rgba(0,0,0,0.1);
  margin: auto;
  font-family: Montserrat, sans-serif;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  &.small {
    padding: 0.6rem;
  }
  &.green {
    background-color: $green;
    border-color: $green;
    transition: all .3s;
    &:hover {
      background-color: white;
      color: $green;
    }
  }
  &.blue {
    background-color: $blue;
    border-color: $blue;
    transition: all .3s;
    &:hover {
      background-color: white;
      color: $blue;
    }
  }
  &.black {
    background-color: $black;
    border-color: $black;
    transition: all .3s;
    &:hover {
      background-color: white;
      color: $black;
    }
  }
  &.red {
    background-color: $red;
    border-color: $red;
    transition: all .3s;
    &:hover {
      background-color: white;
      color: $red;
    }
    @media (max-width: $phone) {
      border-radius: 25px;
    }
  }
  &.outline {
    background-color: $white;
  }
  &.outlineRed {
    background-color: $white;
    color: $red
  }
  &.outlineGreen {
    background-color: $white;
    color: $green;
    border-width: 2px;
    border-color: $green;
  }
  &:disabled {
    opacity: .3;
    cursor: not-allowed;
    &:hover {
      background-color: $green;
      border-color: $green;
      color: white;
    }
  }
  span {
    display: block;
    font-size: .75rem;
    font-weight: 400;
  }
}
