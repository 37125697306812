@import "../../styles/variables";
.footer {
  position: relative;
  .scrollToTop {
    width: 62px;
    height: 62px;
    position: absolute;
    top: -31px;
    left: 15%;
    z-index: 2;
    @media (max-width: $desktop) {
      left: 10%;
    }
  }
  .footerMain {
    background-color: $blue;
    padding: 5.25rem 0;
    color: $white;
  }
  .logo {
    margin-bottom: 2rem;
    max-width: 100%;
    position: relative;
    @media (max-width: $phone) {
      text-align: center;
      max-width: 80px;
      margin: 0 auto 40px;
    }
    &:after {
      @media (max-width: $phone) {
        content: '';
        width: 51px;
        height: 1px;
        background: $white;
        position: absolute;
        bottom: -24px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    svg {
      path {
        @media (max-width: $phone) {
          fill: $white;
        }
      }
    }
  }
  .copyright {
    font-weight: 500;
    margin: 0;
    font-size: 0.8rem;
    @media (max-width: $phone) {
      display: none;
    }
  }
  .footerNav {
    margin-bottom: 2rem;
    @media (max-width: $phone) {
      margin-bottom: 0;
    }
    & ul {
      columns: 3;
      @media (max-width: $desktop) {
        columns: 2;
      }
      @media (max-width: $phone) {
        columns: 1;
        text-align: center;
      }
      & li {
        margin-bottom: 1rem;
        font-size: 16px;
        & a {
          color: $white;
        }
      }
    }
  }
  .social {
    & ul {
      & li {
        margin-bottom: 1rem;
        font-size: 16px;
        @media (max-width: $phone) {
          display: inline-block;
          width: 100%;
          text-align: center;
        }
        & a {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: $white;
          @media (max-width: $phone) {
            justify-content: center;
          }
          & svg {
            color: $lightGreen;
            width: 24px !important;
            height: 24px;
            display: block;
          }
          & span {
            display: block;
            margin-left: 0.75rem;
          }
        }
      }
    }
  }
  .footerLanguage {
    background-color: $black;
    padding: 1.5rem 0;
    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      li {
        text-align: center;
        color: $white;
        font-weight: 500;
        font-size: 16px;
        border-bottom: 4px solid transparent;
        a {
          color: $white;
        }
        &.active {
          border-color: $red;
        }
      }
    }
  }
}
